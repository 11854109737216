import * as React from "react"
import { useContext } from "react"
import Layout from "../social-supermarket/components/Layout"
import { LoginContext } from "components"
import OrdersPage from "../social-supermarket/pages/orders/OrdersPage"
import SEO from "../social-supermarket/components/Seo"

const Orders = () => {
  const loginContext = useContext(LoginContext)
  return loginContext.user ? (
    <Layout>
      <SEO title={"Your Orders"} description={""} />
      <OrdersPage />
    </Layout>
  ) : (
    <Layout />
  )
}

export default Orders
